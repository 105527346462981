import { SearchOfficerTrainingSchoolClassDto } from '@/models/Dtos/searchOfficerTrainingSchoolClassDto';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { IEntityActionBuilder } from '../../EntityActions/EntityAction';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../../models/EntityActionButtons';
import { OfficerTrainingSchoolClassTableData } from '../OfficerTrainingSchoolClassTableData';

export class DeleteOTSClassTableActionBuilder
  implements
    IEntityActionBuilder<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      SearchOfficerTrainingSchoolClassDto
    >
{
  build(
    rowData: OfficerTrainingSchoolClassTableData,
    entityActionButtons: EntityActionButtons<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      SearchOfficerTrainingSchoolClassDto
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'trash',
      text: 'Delete',
      iconFill: '#C81111',
      actionHandler: async () => {
        const confirmed = await entityActionButtons.yesNoModal.open({
          title: 'Delete Class',
          message: 'Are you sure you want to delete this class?'
        });
        if (!confirmed) {
          return;
        }
        entityActionButtons.tableSearchService.loaded = false;
        try {
          await this.delete(rowData, entityActionButtons);
          return entityActionButtons.tableSearchService.queryData();
        } catch (err) {
          if (err) {
            entityActionButtons.store.dispatch('snackBarModule/enqueue', {
              message: `The class failed to delete.`,
              icon: 'x'
            });
          }
        }
      }
    };
  }

  protected delete = async (
    OfficerTrainingSchoolClassTableData: OfficerTrainingSchoolClassTableData,
    entityActionButtons: EntityActionButtons<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      SearchOfficerTrainingSchoolClassDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'officerTrainingSchoolClassModule/delete',
      OfficerTrainingSchoolClassTableData.entityId
    );
  };
}
