import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { SearchOfficerTrainingSchoolClassDto } from '@/models/Dtos/searchOfficerTrainingSchoolClassDto';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { TableDataProvider } from '../models/TableDataProvider';
import { OfficerTrainingSchoolClassTableData } from './OfficerTrainingSchoolClassTableData';

export class OfficerTrainingSchoolClassDataProvider extends TableDataProvider<
  OfficerTrainingSchoolClassTableData,
  OfficerTrainingSchoolClass,
  SearchOfficerTrainingSchoolClassDto
> {
  protected transformer = (
    t: OfficerTrainingSchoolClass
  ): OfficerTrainingSchoolClassTableData =>
    new OfficerTrainingSchoolClassTableData(t);

  protected queryHandler = async (
    searchDto: SearchOfficerTrainingSchoolClassDto
  ): Promise<SearchResponseDto<OfficerTrainingSchoolClass>> => {
    const results: SearchResponseDto<OfficerTrainingSchoolClass> =
      await this.store.dispatch(
        'officerTrainingSchoolClassModule/fetchWithSelectParameters',
        searchDto
      );
    return results;
  };
}
