import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { SearchOfficerTrainingSchoolClassDto } from '@/models/Dtos/searchOfficerTrainingSchoolClassDto';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { TableConfigBuilder } from '../models/TableConfigBuilder';
import { OfficerTrainingSchoolClassTableData } from './OfficerTrainingSchoolClassTableData';
import { EntityActionButtons } from '../models/EntityActionButtons';
import TableSearchService from '../models/TableSearch.service';
import { Store } from 'vuex';
import { RootState } from '@/store/types';
import { ModalMounter } from '@/components/ModalMounter';
import { EditOTSClassTableActionBuilder } from './EntityActions/EditOTSClassTableActionBuilder';
import { DeleteOTSClassTableActionBuilder } from './EntityActions/DeleteOTSClassTableActionBuilder';

export const ReportsOfficerTrainingSchoolClassTableConfigBuilder: TableConfigBuilder<
  OfficerTrainingSchoolClassTableData,
  OfficerTrainingSchoolClass,
  never,
  SearchOfficerTrainingSchoolClassDto
> = (
  tableSearchService: TableSearchService<
    OfficerTrainingSchoolClassTableData,
    OfficerTrainingSchoolClass,
    never,
    SearchOfficerTrainingSchoolClassDto
  >,
  store: Store<RootState>
): ITableConfiguration<
  OfficerTrainingSchoolClassTableData,
  OfficerTrainingSchoolClass,
  never
> => {
  const tableButtons = new EntityActionButtons<
    OfficerTrainingSchoolClassTableData,
    OfficerTrainingSchoolClass,
    SearchOfficerTrainingSchoolClassDto
  >(
    'officerTrainingSchoolClass',
    store,
    tableSearchService,
    ModalMounter.instance.refs.yesNoModal,
    {
      editModal: ModalMounter.instance.refs.officerTrainingSchoolClassModal
    }
  );
  return {
    columnConfigurations: [
      {
        columnTitle: 'Class Number',
        defaultPinned: true,
        render: {
          componentRef: LinkElementComponent,
          componentProps: (rowData) => {
            return {
              displayString: rowData.classNumber,
              linkTo: {
                name: Routes.OTS_ROSTER_DETAILS,
                params: {
                  otsId: rowData.entityId,
                  fromReports: 'true'
                }
              }
            };
          }
        },
        sortKey: 'classNumber'
      },
      {
        columnTitle: 'Start Date',
        render: 'startDate',
        sortKey: 'startDate'
      },
      {
        columnTitle: 'Graduation Date',
        render: 'graduationDate',
        sortKey: 'graduationDate'
      },
      {
        columnTitle: 'On Site',
        render: 'onSite',
        sortKey: 'onSite'
      },
      {
        columnTitle: 'Roster Emailed',
        render: 'rosterEmailed',
        sortKey: 'rosterEmailed'
      },
      tableButtons.buildEntityActionsColumn([
        new EditOTSClassTableActionBuilder(),
        new DeleteOTSClassTableActionBuilder()
      ])
    ]
  };
};
