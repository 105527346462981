import { SearchOfficerTrainingSchoolClassDto } from '@/models/Dtos/searchOfficerTrainingSchoolClassDto';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { IEntityActionBuilder } from '../../EntityActions/EntityAction';
import {
  EntityActionButtons,
  IEntityActionsTableElementComponentProp
} from '../../models/EntityActionButtons';
import { OfficerTrainingSchoolClassTableData } from '../OfficerTrainingSchoolClassTableData';

export class EditOTSClassTableActionBuilder
  implements
    IEntityActionBuilder<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      SearchOfficerTrainingSchoolClassDto
    >
{
  build(
    rowData: OfficerTrainingSchoolClassTableData,
    entityActionButtons: EntityActionButtons<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      SearchOfficerTrainingSchoolClassDto
    >
  ): IEntityActionsTableElementComponentProp {
    return {
      icon: 'pencil',
      text: 'Edit',
      actionHandler: async () => {
        if (
          rowData.entityId === null ||
          entityActionButtons.actionOptions?.editModal === undefined
        ) {
          return;
        }
        try {
          const result = await entityActionButtons.actionOptions.editModal.open(
            rowData.entityId
          );
          await this.edit(result, entityActionButtons);
          entityActionButtons.store.dispatch('snackBarModule/enqueue', {
            message: 'Class updated',
            icon: 'checkGreen'
          });
          return entityActionButtons.tableSearchService.queryData();
        } catch (e) {
          if (e) {
            entityActionButtons.store.dispatch('snackBarModule/enqueue', {
              message: `${entityActionButtons.capitalizedTableDataTypeName} failed to update, please try again.`,
              icon: 'x'
            });
          }
        }
      }
    };
  }

  protected edit = async (
    otsClass: OfficerTrainingSchoolClass,
    entityActionButtons: EntityActionButtons<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      SearchOfficerTrainingSchoolClassDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'officerTrainingSchoolClassModule/update',
      {
        id: otsClass.id,
        payload: otsClass
      }
    );
  };
}
