import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { ITableData } from '../models/TableData';

export class OfficerTrainingSchoolClassTableData implements ITableData {
  entityId: number;
  isArchived: boolean;
  officerTrainingSchoolClass: OfficerTrainingSchoolClass;
  classNumber!: string | null;
  startDate!: Date | null;
  graduationDate!: Date | null;
  onSite!: Date | null;
  rosterEmailed!: Date | null;

  constructor(officerTrainingSchoolClass: OfficerTrainingSchoolClass) {
    this.officerTrainingSchoolClass = officerTrainingSchoolClass;
    this.classNumber = officerTrainingSchoolClass.classNumber;
    this.startDate = officerTrainingSchoolClass.startDate;
    this.graduationDate = officerTrainingSchoolClass.graduationDate;
    this.onSite = officerTrainingSchoolClass.onSite;
    this.rosterEmailed = officerTrainingSchoolClass.rosterEmailed;
    this.isArchived = officerTrainingSchoolClass.isArchived;
    this.entityId = officerTrainingSchoolClass.id;
  }
}
