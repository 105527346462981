
import { Component, Vue } from 'vue-property-decorator';
import PageLayout from '@/components/Layout/PageLayoutComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import OfficerTrainingSchoolClassModal from '@/components/Modals/OfficerTrainingSchoolClass/OfficerTrainingSchoolClassModalComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { OfficerTrainingSchoolClassTableData } from '@/components/Table/OfficerTrainingSchoolClass/OfficerTrainingSchoolClassTableData';
import { SearchOfficerTrainingSchoolClassDto } from '@/models/Dtos/searchOfficerTrainingSchoolClassDto';
import { OfficerTrainingSchoolClassDataProvider } from '@/components/Table/OfficerTrainingSchoolClass/OfficerTrainingSchoolClassDataProvider';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import Routes from '@/router/Routes';
import { StudentDashboardOfficerTrainingSchoolClassTableConfigBuilder } from '@/components/Table/OfficerTrainingSchoolClass/StudentDashboardOfficerTrainingSchoolClassTableConfigBuilder';
import { ReportsOfficerTrainingSchoolClassTableConfigBuilder } from '@/components/Table/OfficerTrainingSchoolClass/ReportsOfficerTrainingSchoolClassTableConfigBuilder';
@Component<OtsClassesPage>({
  components: {
    ButtonComponent,
    OfficerTrainingSchoolClassModal,
    IconComponent,
    PageLayout,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent
  }
})
export default class OtsClassesPage extends Vue {
  fromReports = 'false';

  setLocation(): void {
    if (this.$route.params.fromReports)
      this.fromReports = this.$route.params.fromReports;
  }

  get pathing(): Routes {
    return this.fromReports === 'true'
      ? Routes.REPORTS_DASHBOARD
      : Routes.STUDENT_DASHBOARD;
  }

  get naming(): string {
    return this.fromReports === 'true' ? 'Reports /' : '340th Dashboard /';
  }

  $refs!: {
    officerTrainingSchoolModal: OfficerTrainingSchoolClassModal;
  };

  searchService: TableSearchService<
    OfficerTrainingSchoolClassTableData,
    OfficerTrainingSchoolClass,
    never,
    SearchOfficerTrainingSchoolClassDto
  > | null = null;

  async created(): Promise<void> {
    this.setLocation();
    this.searchService = new TableSearchService<
      OfficerTrainingSchoolClassTableData,
      OfficerTrainingSchoolClass,
      never,
      SearchOfficerTrainingSchoolClassDto
    >(
      this.$store,
      new OfficerTrainingSchoolClassDataProvider(this.$store),
      {},
      { defaultSortProperty: 'startDate' },
      this.fromReports === 'true'
        ? ReportsOfficerTrainingSchoolClassTableConfigBuilder
        : StudentDashboardOfficerTrainingSchoolClassTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
  }

  async createOtsClass(): Promise<void> {
    try {
      if (!this.searchService) {
        return;
      }
      const otsClass = await this.$refs.officerTrainingSchoolModal.open(
        undefined
      );
      await this.$store.dispatch(
        'officerTrainingSchoolClassModule/create',
        otsClass
      );
      this.showSuccess('Class created');
      await this.searchService.queryData();
    } catch (err) {
      if (err) {
        this.showError(JSON.stringify(err));
      }
    }
  }

  showSuccess(message: string): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'checkGreen'
    });
  }

  showError(message = 'An error occurred'): void {
    this.$store.dispatch('snackBarModule/enqueue', {
      message,
      icon: 'caution'
    });
  }
}
